var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "index_404" }, [
      _c("div", [
        _c("img", {
          attrs: { src: require("../assets/images/404-graphic.svg"), alt: "" },
        }),
        _c("h5", [_vm._v("\n      Sorry!\n    ")]),
        _c("h4", [_vm._v("\n      It seems the page can't be found\n    ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }