<template>
  <div class="index_404">
    <div>
      <img src="../assets/images/404-graphic.svg" alt="">
      <h5>
        Sorry!
      </h5>
      <h4>
        It seems the page can't be found
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    // console.log(this.$store.state.wallet.cur_private_key.xprivkey)
  }
}
</script>

<style lang="scss" scoped>
  .index_404 {
    background: url('../assets/images/home_bg.png') no-repeat;
    width: 100vw;
    background-position: 0;
    background-size: cover;
    min-height: calc(100vh - 68px);
    >div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 68px);
    }
    img {
      width: 80vw;
      margin: 25px auto 25px auto;
      display: block;
    }
    h5 {
      color: #fff;
      margin: 15px 0;
      font-size: 3rem;
      text-align: center;
    }
    h4 {
      font-size: 2.2rem;
      margin: 15px 0;
      color: #fff;
      text-align: center;
    }
  }
  @media (min-width: 768px) {
    .index_404 {
      img {
        width: 30vw;
      }
    }
  }
</style>
